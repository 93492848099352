import React, {useState, useMemo, useContext} from 'react'
import AudioManager from 'util/AudioManager';
import trans from 'util/translate';
import Tutorial from 'util/Tutorial';
import axios from 'axios';

const AppContext = React.createContext({});
const apiUrl = e => process.env.REACT_APP_API_URL + '/' + e;

const manager = new AudioManager(apiUrl);
const initialState = {
  language: {
    trans: trans
  },
  app: {
    apiUrl: apiUrl,
    axios: token => axios.create({
      baseURL: process.env.REACT_APP_API_URL + '/',
      headers: {'X-Token': token}
    }),
    started: false,
    welcomeFinished: null,
    history: null

  },
  audio: {
    manager: manager,
    tutorial: new Tutorial(manager)
  },
  menu: {
    items: [],
    position: 0,
    audio: new Audio(),
    reset: true
  },
  help: {
    audio: new Audio(),
  },
  audioPlayer: {
    audio: new Audio(),
    playing: false,
    duration: 0,
    progress: 0
  },
  log: {items: []}
};

const useAppState = () => {

  const [state, setState] = useState(initialState);


  const actions = useMemo(() => getActions(setState), [setState]);

  return {state, actions}
};

const merge = (state, field, newState) => {
  const merged = {
    [field]: {...state[field], ...newState}
  };
  return {...state, ...merged};
};

const getActions = setState => ({
  menu: {
    next: () => {
      setState(s => {
        const position = s.menu.position + 1 >= s.menu.items.length ? 0 : s.menu.position + 1;
        return merge(s, 'menu', {
          position: position,
          reset: false,
          activeItem: s.menu.items[position]
        });
      })
    },
    prev: () => {
      setState(s => {
        const position = s.menu.position === 0 ? s.menu.items.length - 1 : s.menu.position - 1;
        return merge(s, 'menu', {
          position: position,
          reset: false,
          activeItem: s.menu.items[position]
        });
      })
    },
    resetMenu: () => {
      setState(s => merge(s, 'menu', {reset: true}))
    },
    setItems: (items) => {
      setState(s => merge(s, 'menu', {items: items, position: 0, reset: true, activeItem: items[0]}))
    },
  },
  app: {
    setStarted: (started) => {
      setState(s => merge(s, 'app', {started: started}))
    },
    setHistory: (history) => {
      setState(s => merge(s, 'app', {history: history}))
    },
    setWelcomeFinished: v => {
      setState(s => merge(s, 'app', {welcomeFinished: v}))
    }
  },
  audioPlayer: {
    togglePlayback: () => {
      setState(s => merge(s, 'audioPlayer', {playing: !s.audioPlayer.playing}))
    },
    setDuration: duration => {
      setState(s => merge(s, 'audioPlayer', {duration: duration}))
    },
    setProgress: progress => {
      setState(s => merge(s, 'audioPlayer', {progress: progress}))
    }
  },
  log: {
    append: (item) => {
      setState(s => merge(s, 'log', {items: [...s.log.items, item]}))
    }
  }
});

const useAppContext = (name) => {
  const context = useContext(AppContext);
  if (typeof name === 'string') {
    const {state, actions} = context;
    return {...state[name], ...actions[name]}
  } else {
    return context;
  }
};

export {AppContext, useAppState, useAppContext}