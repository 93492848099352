import React, {useEffect, useState} from "react";
import {fromEvent, merge} from 'rxjs';

export default function useUserInteracted(config) {
  const [keyPressed, setKeyPressed] = useState(false);
  useEffect(() => {
    const onInteract = () => {
      setKeyPressed(true);
      stream.unsubscribe();
    };
    const up = fromEvent(document, 'keyup');
    const click = fromEvent(document, 'click');
    const stream = merge(up, click).subscribe(onInteract);
    return () => {
      stream.unsubscribe();
    }
  });
  return keyPressed;
};