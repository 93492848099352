import React, {useState, useEffect} from 'react';
import './style.scss';

export default function Title({
  title,
  fadeIn,
  blink,
  subtitle,
  className,
  onClick
}) {

  const [visible, setVisible] = useState(!fadeIn);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(true);
    }, 100);
    return () => {
      clearTimeout(timeout);
    }
  }, []);

  let _className = className ? className : '';
  _className = blink ? _className + " blink" : _className;
  _className = visible ? _className + " active" : _className;

  const _onClick = onClick ? onClick : () => {}
  return (
    <div onClick={_onClick} className={"Title " + _className}>
      <div className="content">
        {title && <h1>{title}</h1>}
        {subtitle && <h2>{subtitle}</h2>}
      </div>
    </div>
  )
}