import React, {useEffect, useState} from "react";
import {fromEvent} from 'rxjs';
import MatrixTyper from 'components/MatrixTyper';

import './Test.scss';


export default function Test({history}) {
  const [keys, setKey] = useState([]);

  useEffect(() => {
    const down = fromEvent(document, 'keydown').subscribe((e) => {
      e.preventDefault();
      setKey(["KD " + e.keyCode, ...keys]);
    });
    const up = fromEvent(document, 'keyup').subscribe((e) => {
      e.preventDefault();
      setKey(["KU " + e.keyCode, ...keys]);
    });
    return () => {
      down.unsubscribe();
      up.unsubscribe();
    }
  });


  if (true) {
    return (
      <ul className="reset Test">
        {keys.map((k, i)=> {
          return <li key={i}>{k}</li>
        })}
      </ul>
    );
  }

  return (
    <div>

    </div>
  );


}

