import language from 'data/language/current';


const series = {};
const seriesNoRepeat = {};

language._config.series.map(name => {
  series[name] = 0;
});

language._config.seriesNoRepeat.map(name => {
  seriesNoRepeat[name] = true;
});

const test = false;
export default function trans(ns) {
  return (key, params = {}) => {
    if (test) {
      return key;
    }
    let text = typeof language[ns][key] === 'undefined' ? key : language[ns][key];
    if (Array.isArray(text)) {
      if (typeof series[key] !== 'undefined') {
        const len = text.length;
        text = text[series[key]];
        if (series[key] !== len - 1) {
          series[key] = series[key] + 1;
        } else if (typeof seriesNoRepeat[key] !== true) {
          series[key] = 0;
        }
      } else {
        text = text[Math.floor(Math.random() * text.length)];
      }
    }
    return replace(text, params);
  }
};

export const replace = (text, params = {}) => {
  const items = [];
  text.replace(/\{(\w+)\}/g, (text, name) => {
    items.push(name);
  });
  items.forEach(name => {
    const data = typeof params[name] !== 'undefined' ? params[name] : trans('general')(name);
    text = text.replace('\{' + name + '\}', data);
  });
  return text;
};

