import React from 'react';
import Gallery from 'components/Gallery';
import './style.scss';


export default function CurrentItem({item}) {
  const photo = item.photos.length ? item.photos[0] : false;
  let _color = 'black';
  if (photo && photo.color) {
    const [r,g,b] = photo.color;
    _color = `rgb(${r},${g},${b})`
  }
  return (
    <div className="CurrentItem">
      <Gallery item={item}/>
      <div className="content">
        {item.title && <h1>{item.title}</h1>}
        {item.subtitle && <h2>{item.subtitle}</h2>}
      </div>
    </div>
  )
}