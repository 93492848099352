import React, {useEffect, useState} from "react";
import {useAppContext} from 'hooks/useAppState';
import useUserInteracted from 'hooks/useUserInteracted';
import useLocalStorage from 'hooks/useLocalStorage';
import MatrixTyper from 'components/MatrixTyper';

import './Startup.scss';
import {Link} from "react-router-dom";


export default function Startup({history, autoStart = true}) {
  const {manager} = useAppContext('audio');
  const t = useAppContext('language').trans('startup');
  const [matrixReady, showMatrix] = useState(false);
  const [typerReady, showTyper] = useState(false);
  const [typerHalted, haltTyper] = useState(false);
  const [startupComplete, completeStartup] = useLocalStorage('startupComplete', null);

  useEffect(() => {
    if (!autoStart) {
      return;
    }

    const redirect = () => {
      setTimeout(() => {
        history.push('/introduce')
      }, 2000)
    };

    const playCrash = () => {
      haltTyper(true);
      setTimeout(() => {
        manager.play('help', t('startup crashed'), redirect);
      }, 5000)
    };

    const playNewLanguage = () => {
      manager.source('help', '/audio/switched_language.mp3');
      manager.play('help', null, playCrash)
    };
    manager.playRobot('help', t('booting up'), playNewLanguage);
    showTyper(true);
  }, []);


  const onTyperFinished = () => {
    completeStartup(true);
    const onDone = () => {
      // history.push('/')
    };
    manager.playRobot('help', t('startup crashed'), onDone);
  };


  return (
    <div className="Startup">
      {typerReady && <MatrixTyper halt={typerHalted}/>}
    </div>
  )
}

