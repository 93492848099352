import React, {useEffect, useState, useRef} from 'react';
import Icon from 'components/Icon';
import Button from 'components/Button';
import {useAppContext} from 'hooks/useAppState';
import './style.scss';


function Menu({options, name, exit}) {
  const menu = useAppContext('menu');
  const [count, setCount] = useState(0);
  const {manager} = useAppContext('audio');
  const {history} = useAppContext('app');
  const el = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount(count + 1)
    }, 1000);

    return () => {
      clearInterval(interval)
    }
  }, [count]);

  const defaultOptions = {
    right: {
      onClick: menu.next,
      icon: 'stepForward',
    },
    left: {
      onClick: menu.prev,
      icon: 'stepBackward'
    },
    center: {
      onClick: () =>manager.toggle('main'),
      icon: manager.isPlaying('main') ? 'pause' : 'play'
    },
  };

  if (name !== 'menu_home') {
    defaultOptions.top = {
      onClick: exit,
      icon: 'home'
    }
  }
  const _options = {...defaultOptions, ...options};

  const _onClick = onClick => () => {
    onClick(menu.activeItem, {manager, history})
  };
  return (
    <div key={name} ref={el} className="Menu">
      {Object.keys(_options).map(name => {
        const {onClick, icon, content, highlight} = _options[name];
        return (
          <Button key={name}
                  name={name}
                  highlight={typeof highlight !== 'undefined' ? highlight : true}
                  className={"item " + name}
                  onClick={_onClick(onClick)}
          >
            {icon && <Icon name={icon}/>}
            {content && content}
          </Button>)
      })}
    </div>
  )
}

export default Menu;