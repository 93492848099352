import React, {useState} from 'react';

import './style.scss';

export default function Button({
  className,
  children,
  color,
  onClick,
  name,
  highlight = true
}) {
  const [wasClicked, setClicked] = useState(false);
  let timeout, _className = 'Button ' + className;
  const _onClick = () => {
    if (highlight) {
      clearTimeout(timeout);
      setClicked(true);
      timeout = setTimeout(() => {
        setClicked(false);
      }, 500);
    }
    onClick && onClick();
  };

  _className = wasClicked ? _className + ' active' : _className;

  _className = _className + ' ' + color;

  return (
    <div data-name={name} className={_className} onClick={_onClick}>
      {children}
    </div>
  )
}