import React from "react";
import Icon from 'components/Icon';

import './style.scss';

export default function Error() {

  return (
    <div className="Error fixed-center">
      <Icon name="error"/>
    </div>
  );
}
