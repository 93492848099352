class Tutorial {

  constructor(audio) {
    this.audio = audio;
    this.position = 0;
    this.stopped = true;
  }

  activate(elements, nodes) {
    this.addClassFor(elements, nodes, 'active');
  }

  deactivate(elements, nodes) {
    this.removeClassFor(elements, nodes, 'active');
  }

  removeClassFor(elements, nodes, className) {
    elements.forEach(name => {
      if (typeof nodes[name] !== 'undefined') {
        nodes[name].classList.remove(className);
      }
    })
  }

  addClassFor(elements, nodes, className) {
    elements.forEach(name => {
      if (typeof nodes[name] !== 'undefined') {
        nodes[name].classList.remove(className);
        nodes[name].classList.add(className);
      }
    })
  }

  play(menu, items) {
    if (!items.length) {
      return;
    }
    const nodes = [...menu.current.childNodes].reduce((agg, x) => {
      return {...agg, [x.dataset.name]: x}
    }, {});


    let {text, elements} = items[this.position];
    this.activate(elements, nodes);
    const onDone = () => {
      this.deactivate(elements, nodes);
      if (this.position === items.length - 1) {
        this.reset();
      } else {
        this.position = this.position + 1;
        this.play(menu, items);
      }
    };
    this.audio.play('help', text, onDone)
  }

  reset() {
    this.stopped = true;
    this.position = 0;
  }
}

export default Tutorial;