import React, {useRef, useEffect, useState} from 'react';
import typer from 'typer-js'
import text from './text';

import './style.scss';

export default function MatrixTyper({onReady, halt = false}) {
  const ref = useRef(null);
  const [t, setTyper] = useState(null);

  useEffect(() => {
    let timeout;
    if (halt) {
      t.halt();
      timeout = setTimeout(() => {
        onReady && onReady();
      }, 3000)
    }
    return () => {
      clearTimeout(timeout);
    }
  }, [halt]);

  useEffect(() => {

    if (!ref) {
      return;
    }
    const _t = typer(ref.current, 4);
    _t.line(text)
      .run(onReady);
    setTyper(_t);

    const interval = setInterval(() => {
      window.scrollTo(0, ref.current.scrollHeight);
    }, 4);
    return () => {
      clearInterval(interval);
    }
  }, [ref]);

  return (
    <div ref={ref} className="MatrixTyper">
    </div>
  )
}
