import React from "react";
import Button from 'components/Button';
import Icon from 'components/Icon';

import './style.scss';

const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 'backspace', 0, 'check'];

export default function Keypad({onAdd, onDelete, onConfirm, password}) {
  return (
    <div className="Keypad">
      <ul className="reset password">
        {password.split('').map((char, i) => {
            return (<li key={'' + i + char }><Icon name="asterisk"/></li>)
          }
        )}
      </ul>
      <ul className="numbers reset">
        {numbers.map(number => {
          let content, color, onClick, type;
          switch (number) {
            case "backspace":
              content = <Icon name="backspace"/>;
              type = 'icon';
              color = 'red';
              onClick = onDelete;
              break;
            case "check":
              content = <Icon name="check"/>;
              type = 'icon';
              color = 'green';
              onClick = onConfirm;
              break;
            default:
              type = 'text';
              color = 'blue';
              content = number;
              onClick = onAdd(number);
          }
          return (
            <li key={number} className={"number " + type}>
              <Button color={color} onClick={onClick}>
                <div className="inner">
                  {content}
                </div>
              </Button>
            </li>)
        })}
      </ul>
    </div>
  );
}
