import React from "react";

import create from 'util/createPage';

const selectItem = (item, props) => {
  props.manager.pause();
  props.history.push(item.id);
};

export default create({
  name: 'home',
  endPoint: 'audio/menu',
  selectItem: selectItem,
  menu: {
    center: {
      onClick: selectItem
    }
  }
});
