import React, {useEffect, useState} from "react";
export const getViewport = w => {
  const width = w.innerWidth && w.document.documentElement.clientWidth ?
    Math.min(w.innerWidth, w.document.documentElement.clientWidth) :
  w.innerWidth ||
  w.document.documentElement.clientWidth ||
  w.document.getElementsByTagName('body')[0].clientWidth;
  return {
    h: w.document.documentElement.clientHeight,
    w: width
  };
};

export default function useViewport() {
  const [viewport, setViewport] = useState(null);

  const _setViewport = () => {
    setViewport(getViewport(window))
  };

  useEffect(() => {
      _setViewport();

      window.addEventListener("resize", _setViewport);
      return () => {
        window.removeEventListener("resize", _setViewport);
      }
    }, []
  );
  return viewport;
};

