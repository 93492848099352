import React from "react";

import create from 'util/createPage';

export default create({
  name: 'rockhill',
  endPoint: 'audio/rockhill',
  selectItem: (item, props) => {
    props.manager.toggle('main');
    //  props.manager.toggle('main');
  }
});
