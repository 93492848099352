import React, {useEffect, useState, useRef} from "react";
import {useAppContext} from 'hooks/useAppState';
import useViewport from 'hooks/useViewport';
import {CSSTransitionGroup} from 'react-transition-group'

import './style.scss';


const fade = false;
export default function Gallery({item}) {
  const [position, setPosition] = useState(0);
  const viewport = useViewport();


  const {activeItem} = useAppContext('menu');
  const photo = item.photos.length ? item.photos[position] : [{url: '/img/brian.jpg', color: [0, 0, 0]}];

  const {color, url} =photo;

  let _color = 'black';
  if (color) {
    const [r,g,b] = color;
    _color = `rgb(${r},${g},${b})`
  }

  useEffect(() => {
    let timeout;
    if (activeItem !== item) {
      const newPosition = position === item.photos.length - 1 ? 0 : position + 1;
      timeout = setTimeout(() => {
        setPosition(newPosition);
      }, 250);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [activeItem]);

  const img = (<div className="inner"><img key={url} alt="" src={url + '/768/1024'}/></div>);

  return (

    <div className="Gallery" style={{backgroundColor: _color}}>
      <div className="inner">
        {viewport && <img key={url} alt="" src={`${url}/${viewport.w}/${viewport.h}`}/>}
      </div>
    </div>

  );
}