import React, {useState, useEffect} from "react";
import useLocalStorage from 'hooks/useLocalStorage';
import useUserInteracted from 'hooks/useUserInteracted';
import {useAppContext} from 'hooks/useAppState';
import useKeyMaps from 'hooks/useKeyMaps';
import PasswordEntry from 'components/PasswordEntry';
import Keypad from 'components/Keypad';
import useAxios from '@use-hooks/axios';
import md5 from 'md5';
import './Login.scss';

export default function Login({history}) {
  const userInteracted = useUserInteracted();
  const [password, setPassword] = useState('');
  const [inputReady, allowInput] = useState(false);
  const [typerText, setTyperText] = useState(null);
  const [ready, setReady] = useState(false);
  const [token, setToken] = useLocalStorage('token', null);
  const [startupComplete] = useLocalStorage('startupComplete', null);
  const {manager} = useAppContext('audio');
  const {axios} = useAppContext('app');

  const t = useAppContext('language').trans('login');
  const tg = useAppContext('language').trans('general');

  const {response, error} = useAxios({
    axios: axios(md5(password)),
    url: 'auth',
    method: 'POST',

    trigger: ready ? true : undefined
  });

  useEffect(() => {

    allowInput(true);
    const text = t('enter password');
    manager.playRobot('help', text, () => allowInput(true));
    setTyperText(text);


  }, []);


  useEffect(() => {
    if (error) {
      manager.playRobot('help', t("wrong password") + t("password hint"));
      setReady(false);
      setPassword('');
    }
  }, [error]);


  useEffect(() => {
    if (response) {
      setToken(md5(password));
      const onDone = () => {
        history.push('/startup');
      };
      manager.playRobot('help', t("correct password"), onDone);

    }
  }, [response]);


  const onAdd = (number) => {
    return () => {
      if (password.length <= 5) {
        setPassword(password + number);
        manager.playRobot('help', t('' + number));
      } else {
        manager.playRobot('help', t("thats enough digits"));
      }
    }
  };
  const onDelete = () => {
    setPassword(password.slice(0, password.length - 1));
    manager.playRobot('help', t('deleting number'));
  };

  const onConfirm = () => {
    if (password.length <= 5) {
      manager.playRobot('help', t('not enough digits', {
        number: password.length,
        numberRemaining: 6 - password.length
      }));
    } else {
      manager.playRobot('help', t('checking password '), () => setReady(true));
    }
  };


  const keyMapConfig = {
    0: onAdd(0),
    1: onAdd(1),
    2: onAdd(2),
    3: onAdd(3),
    4: onAdd(4),
    5: onAdd(5),
    6: onAdd(6),
    7: onAdd(7),
    8: onAdd(8),
    9: onAdd(9),
    STOP: onDelete,
    BACKSPACE: onDelete,
    ENTER: onConfirm,
    SPACEBAR: onConfirm
  };

  useKeyMaps({
    keyboard: keyMapConfig,
    remote: keyMapConfig
  }, inputReady);


  return (
    <div className="Login">
      {<Keypad
        onAdd={onAdd}
        onConfirm={onConfirm}
        onDelete={onDelete}
        password={password}
      />}
    </div>
  );
}

