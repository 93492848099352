import React, {useRef, useState, useEffect} from "react";
import useViewport from 'hooks/useViewport';
import './style.scss';

export default function Matrix({onReady, speed}) {
  const [ready, setReady] = useState(false);
  const ref = useRef(null);
  const viewport = useViewport();

  useEffect(() => {
    if (!ready) {
      return;
    }
    onReady && onReady(true);
  }, [ready]);

  useEffect(() => {
    if (!viewport) {
      return;
    }
    if (!ref) {
      return;
    }

    console.log('draw');

    const canvas = ref.current,
      ctx = canvas.getContext('2d');

    canvas.width = viewport.w;
    canvas.height = viewport.h;

    const letters = 'ABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZ'.split(''),
      fontSize = 10,
      columns = canvas.width / fontSize;

    var drops = [];
    for (var i = 0; i < columns; i++) {
      drops[i] = 1;
    }
    let ii = 0;

    function draw() {
      ctx.fillStyle = 'rgba(0, 0, 0, .1)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      for (var i = 0; i < drops.length; i++) {
        var text = letters[Math.floor(Math.random() * letters.length)];
        ctx.fillStyle = '#0f0';
        ctx.fillText(text, i * fontSize, drops[i] * fontSize);
        drops[i]++;
        if (drops[i] * fontSize > canvas.height && Math.random() > .95) {
          drops[i] = 0;
        }
      }
      if (fontSize * ii > canvas.height && !ready) {
        setReady(true);
      }
      ii++;
    }

    const interval = setInterval(draw, speed);
    return function () {
      clearInterval(interval)
    }
  }, [ref, viewport]);


  return (
    <div className="Matrix">
      <canvas ref={ref}/>
    </div>
  )
}

