import React from "react";
import create from 'util/createPage';


const selectItem = (item, props) => {
  props.manager.toggle('main');
};

export default name => create({
  name: name,
  endPoint: 'audio/' + name,
  selectItem: selectItem
});
