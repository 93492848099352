import React, {useEffect} from "react";
import useAxios from '@use-hooks/axios';
import {useAppContext} from 'hooks/useAppState';
import useLocalStorage from 'hooks/useLocalStorage';
import useKeyHold from 'hooks/useKeyHold';
import useKeyMaps from 'hooks/useKeyMaps';
import AudioPlayer from 'components/AudioPlayer';
import Slider from 'components/Slider';
import Error from 'components/Error';
import Menu from 'components/Menu';
import Loading from 'components/Loading';
import {replace} from 'util/translate';
import playTitle from 'util/playTitle';


export default function ({endPoint, menu = {}, name, selectItem}) {
  return function ({history}) {
    const [token] = useLocalStorage('token', null);
    const [welcomePlayed, setWelcomePlayed] = useLocalStorage('welcome', {});
    const {axios, setHistory, started} = useAppContext('app');
    const {setItems, resetMenu, items, position, activeItem} = useAppContext('menu');
    const {manager} = useAppContext('audio');
    const tg = useAppContext('language').trans('general');
    const resetWelcome = useKeyHold(40, 500);
    useEffect(() => {
      if (!resetWelcome) {
        return;
      }
      setWelcomePlayed({});
      console.log(resetWelcome);
    }, [resetWelcome]);

    useEffect(() => {
      setHistory(history)
    }, [setHistory, history]);

    useEffect(() => {
      if (!started) {

      }
    }, [started, history]);

    useEffect(() => {
      if (!activeItem) {
        return;
      }
      const onCurrentReady = ()=> {
        playTitle(activeItem, manager, tg);
      }
      const onWelcomeDone = () => {
        // const text = tg("current item " + name) + ' ';
        // manager.play('help', text, onCurrentReady);
        if (!welcomePlayed[name]) {
          setWelcomePlayed({...welcomePlayed, [name]: true});
        }
      }

      const msg = welcomePlayed[name] ? tg('welcome to ' + name) : tg('welcome to ' + name + ' first');

      if (msg === 'Shall we play a song') {
        const playRobot = () => {
          manager.playRobot('help', msg);
        }
        manager.play('help', 'welcome to your music library', playRobot);
      } else {
        manager.play('help', msg, onWelcomeDone);
      }
      return () => {
        manager.unsubscribe();
      }
    }, [items, manager]);

    const {response, error} = useAxios({
      axios: axios(token),
      url: endPoint,
      method: 'GET',
      trigger: true,
      customHandler: (e, r)=> {
        if (r && r.data && r.data.items) {
          setItems(r.data.items);
        }
      }
    });
    const _selectItem = () => {
      selectItem(activeItem, {history, manager})
    };

    const exit = () => {
      manager.pause();
      if (name === 'home') {
        manager.play('help', tg('already  home'));
      } else {
        manager.unsubscribe();
        const goHome = () => {
          resetMenu();
          history.push('/home');
        }
        manager.play('help', tg('going  home'), goHome);
      }
    };

    const keyMapConfig = {
      ESCAPE: exit,
      STOP: exit,
      ENTER: _selectItem,
      PLAYPAUSE: _selectItem,
      SPACEBAR: _selectItem
    };

    useKeyMaps({
      keyboard: keyMapConfig,
      remote: keyMapConfig
    });


    if (error) return (<Error />);
    if (!activeItem) return (<Loading />);
    let hasAudioContent = activeItem.audio.content || activeItem.contentText;
    return (
      <div className="Home">
        {hasAudioContent && <AudioPlayer />}
        <Menu exit={exit} name={'menu_' + name} options={menu}/>
        <Slider name={name} items={items} position={position}/>
      </div>
    );
  }
}
