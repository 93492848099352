import React, {useEffect, useState} from "react";
import {fromEvent} from 'rxjs';
import {map, filter} from 'rxjs/operators';

export default function useKeyMaps(key, minDuration = 2000) {
  const [keys, setKey] = useState([]);
  const [start, setStart] = useState(null);
  const [pressed, setPressed] = useState(0);
  useEffect(() => {
    const mapKeyCode = map(e => e.keyCode);
    const filterKey = filter(k => k === key);

    const onDown = k => {
      if (start === null) {
        setStart(new Date().getTime());
      }
    };

    const onUp = k => {
      const time = new Date().getTime() - start;
      if (time > minDuration) {
        setPressed(pressed + 1);
      }
      setStart(null);
    };

    const down = fromEvent(document, 'keydown')
      .pipe(mapKeyCode)
      .pipe(filterKey)
      .subscribe(onDown);

    const up = fromEvent(document, 'keyup')
      .pipe(mapKeyCode)
      .pipe(filterKey)
      .subscribe(onUp);

    return () => {
      down.unsubscribe();
      up.unsubscribe();
    }
  });

  return pressed;
};