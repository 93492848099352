import React, {useEffect} from "react";
import './Reset.scss';
export default function Reset({history}) {
  useEffect(() => {
    localStorage.clear();
  }, []);


  return (
    <div className="Reset">
      D
    </div>
  );


}

