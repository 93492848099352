export default class Keys {

  static map = {
    0: 48,
    1: 49,
    2: 50,
    3: 51,
    4: 52,
    5: 53,
    6: 54,
    7: 55,
    8: 56,
    9: 57,
    BACKSPACE: 8,
    SPACEBAR: 32,
    ESCAPE: 27,
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40,
    FAST_FORWARD: 228,
    REWIND: 227,
    PLAYPAUSE: 179,
    STOP: 178,
    BACK: 34,
    FORWARD: 33,
    ENTER: 13,
    PAGE_UP: 33,
    PAGE_DOWN: 34
  };

  static getSwappedMap() {
    const swapped = {};
    Object.keys(Keys.map).forEach((key) => {
      swapped[Keys.map[key]] = key;
    });
    return swapped;
  }

  static getCode(name) {
    return Keys.map[name] ? Keys.map[name] : null;
  }

  static getName(code) {
    const map = Keys.getSwappedMap();
    return map[code] ? map[code] : null;
  }
}