import React from "react";

import Test from './pages/Test';
import Logger from 'components/Logger';
import Start from 'pages/Start';
import Logout from 'pages/Logout';
import Introduce from 'pages/Introduce';
import Startup from 'pages/Startup';
import Login from 'pages/Login';
import Home from 'pages/Home';
import Seamas from 'pages/Seamas';
import Rockhill from 'pages/Rockhill';
import Music from 'pages/Music';
import Brian from 'pages/Brian';
import Reset from 'pages/Reset';


import {BrowserRouter as Router, Route} from "react-router-dom";
import {AppContext, useAppState} from 'hooks/useAppState';

import './App.scss';



export default function App() {
  const {state, actions} = useAppState();
  return (
    <Router>
      <AppContext.Provider value={{state, actions}}>
        <div>
          {false && <Logger/>}
          <Route exact path="/" component={Start}/>
          <Route path="/introduce" component={Introduce}/>
          <Route path="/rockhill" component={Rockhill}/>
          <Route path="/startup" component={Startup}/>
          <Route path="/login" component={Login}/>
          <Route path="/test" component={Test}/>
          <Route path="/reset" component={Reset}/>
          <Route path="/brian" component={Brian}/>
          <Route path="/music" component={Music}/>
          <Route path="/home" component={Home}/>
          <Route path="/seamas" component={Seamas}/>
          <Route path="/logout" component={Logout}/>
        </div>
      </AppContext.Provider>
    </Router>
  );
}
