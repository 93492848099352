export default function playTitle(activeItem, manager, tg) {
  if (activeItem.audio.title) {
    manager.source('menu', activeItem.audio.title);
    manager.play('menu');
  } else if (activeItem.titleText) {
    if (activeItem.titleText === 'caoimhe_') {
      manager.playRobot('menu', tg(activeItem.titleText));
    } else {
      manager.play('menu', tg(activeItem.titleText));
    }
  } else {
    const text = `<speak> ${activeItem.title} -  <break time="500ms"/> ${activeItem.subtitle ? activeItem.subtitle : ''} </speak>`;
    manager.play('menu', text);
  }
}