import React, {useEffect, useState} from "react";
import {useAppContext} from 'hooks/useAppState';
import './style.scss';

export default function AudioPlayer() {
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState(0);
  const {manager} = useAppContext('audio');
  const {apiUrl} = useAppContext('app');
  const {activeItem} = useAppContext('menu');
  const tg = useAppContext('language').trans('general');

  // Set source
  useEffect(() => {
    if (activeItem.audio.content) {
      manager.source('main', activeItem.audio.content);
    } else if (activeItem.contentText) {
      const text = tg(activeItem.contentText);
      manager.source('main', manager.createUrl(text));
    }
  }, [activeItem, apiUrl, manager]);

  // Duration & Progress
  useEffect(() => {
    const main = manager.getPlayer('main');
    const onDurationChange = () => setDuration(main.duration);
    const onProgress = () => setProgress(main.currentTime);
    manager.subscribe('main', 'durationchange', onDurationChange);
    manager.subscribe('main', 'timeupdate', onProgress);
    return () => {
      manager.unsubscribe('main', 'durationchange', onDurationChange);
      manager.unsubscribe('main', 'timeupdate', onProgress);
    }
  }, [manager, setDuration, setProgress]);

  const main = manager.getPlayer('main');

  const _progress = Math.floor((main.currentTime / main.duration) * 100);

  return (
    <></>
  );
}