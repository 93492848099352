import React from "react";
import Icon from 'components/Icon';

import './style.scss';

export default function Loading() {

  return (
    <div className="Loading fixed-center">
      <h1>Loading</h1>
      <Icon spin={true} name="spinner"/>
    </div>
  );
}
