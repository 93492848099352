import React, {useState, useEffect} from "react";
import useLocalStorage from 'hooks/useLocalStorage';
import './Login.scss';

export default function Logout({history}) {
  const [token, setToken] = useLocalStorage('token', null);
  const [startupComplete, setStartupComplete] = useLocalStorage('startupComplete', null);

  useEffect(() => {
    setToken(null);
    setStartupComplete(null);
    const onDone = () => {
      history.push('/');
    };

    setTimeout(onDone, 500);
  }, []);
  return (
    <div className="Logout">
        Test
    </div>
  );
}

