import pako from 'pako';

class AudioManager {


  constructor(createUrl) {
    this.subscriptions = [];
    this.players = {
      menu: new Audio(),
      help: new Audio(),
      main: new Audio()
    };
    this.generatePath = createUrl('generate/');
  }

  encode(text) {
    return encodeURIComponent(btoa(pako.gzip(text, {to: 'string'})));
  }

  createUrl(text) {
    return this.generatePath + this.encode(text);
  }

  createRobotUrl(text) {
    return this.generatePath + 'robot/' + this.encode(text);
  }

  each(fn) {
    Object.keys(this.players).forEach(name => fn(this.getPlayer(name)));
    return this;
  }

  getPlayer(name) {
    return this.players[name];
  }

  action(name, fn) {
    if (typeof name === 'undefined') {
      this.each(fn);
    } else {
      fn(this.getPlayer(name));
    }
    return this;
  }

  subscribeOnce(name, event, fn) {
    const unsubscribe = this.unsubscribe.bind(this);
    const _fn = () => {
      setTimeout(() => {
        fn();
        unsubscribe(name, event, _fn);
      }, 1);
    };
    this.subscribe(name, event, _fn)
  }

  subscribe(name, event, fn) {
    this.subscriptions.push({name, event, fn});
    this.getPlayer(name).addEventListener(event, fn);
  }

  unsubscribe(name, event, fn) {
    if (name) {
      this.subscriptions = this.subscriptions.filter(item => item !== {name, event, fn});
      this.getPlayer(name).removeEventListener(event, fn);
    } else {
      const remove = ({name, event, fn}) => {
        this.getPlayer(name).removeEventListener(event, fn);
      };
      this.subscriptions.forEach(remove.bind(this));
    }
  }

  source(name, value) {
    const player = this.getPlayer(name);
    if (typeof value === 'undefined') {
      return player.src;
    } else {
      player.src = value;
    }
  }

  getProperty(name, prop) {
    return this.getPlayer(name)[prop];
  }

  sourceIs(name, value) {
    return value === this.source(name);
  }

  pause(name) {
    this.action(name, p => p.pause());
    return this;
  }

  isPlaying(name) {
    return !this.getPlayer(name).paused;
  }

  playRobot(name, text, callback) {
    text = this.createRobotUrl(text);
    this.play(name, text, callback);
  }

  play(name, text, callback) {
    this.pause();

    if (typeof text !== 'undefined' && text !== null) {
      text = text.includes('http') ? text : this.createUrl(text);
      this.action(name, p => p.src = text);
    }
    setTimeout(() => {
      if (typeof callback === 'function') {
        this.subscribeOnce(name, 'ended', callback);
      }
      this.action(name, p => p.play());
    }, 10);
    return this;
  }

  toggle(name) {
    if (this.isPlaying(name)) {
      this.pause(name);
    } else {
      this.play(name);
    }
  }
}

export default AudioManager;