import React, {useRef, useEffect} from "react";
import {Carousel} from 'react-responsive-carousel';
import useKeyMaps from 'hooks/useKeyMaps';
import {useAppContext} from 'hooks/useAppState';
import CurrentItem from 'components/CurrentItem';
import playTitle from 'util/playTitle';

import './style.scss';

export default function Slider({items, name, renderItem}) {
  const {position, activeItem, next, reset, prev} = useAppContext('menu');
  const {manager} = useAppContext('audio');
  const tg = useAppContext('language').trans('general');
  const ref = useRef(null);

  const decrement = () => {
    prev();
  };

  const increment = () => {
    next();
  };

  useEffect(() => {
    ref.current.moveTo(position);
  }, [position, ref]);


  useEffect(() => {
    if (reset) {
      return;
    }
    playTitle(activeItem, manager, tg);

  }, [activeItem, reset, manager]);

  useKeyMaps({
    'keyboard': {
      LEFT: decrement,
      RIGHT: increment
    },
    'remote': {
      PAGE_UP: decrement,
      PAGE_DOWN: increment
    },
  });

  const _renderItem = renderItem ? renderItem : item => <CurrentItem item={item}/>


  return (
    <Carousel
      key={name}
      className={'Slider'}
      ref={ref}
      showArrows={false}
      showStatus={false}
      showIndicators={false}
      showThumbs={false}
      emulateTouch={false}
      infiniteLoop={true}
    >
      {items.map(item => {
          return (
            <div key={item.id} className="Item">
              {_renderItem(item)}
            </div>
          );
        }
      )}
    </Carousel>
  );
}