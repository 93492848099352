import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {
  faStepForward,
  faStepBackward,
  faPlay,
  faPause,
  faUndoAlt,
  faLongArrowAltLeft,
  faHome,
  faExclamation,
  faCircleNotch,
  faBackspace,
  faCheck,
  faAsterisk
} from '@fortawesome/free-solid-svg-icons'
import './style.scss';

const icons = {
  stepForward: faStepForward,
  stepBackward: faStepBackward,
  play: faPlay,
  pause: faPause,
  replay: faUndoAlt,
  back: faLongArrowAltLeft,
  home: faHome,
  error: faExclamation,
  spinner: faCircleNotch,
  backspace: faBackspace,
  check: faCheck,
  asterisk: faAsterisk
};

export default function Icon({name, spin}) {
  return (
    <FontAwesomeIcon
      icon={icons[name]}
      spin={spin ? true : false}
      size="6x"
    />
  )
}