import React, {useEffect, useState} from "react";
import {useAppContext} from 'hooks/useAppState';
import useKeyMaps from 'hooks/useKeyMaps';
import Title from 'components/Title';
import Matrix from 'components/Matrix';

import './Start.scss';

export default function Introduce({history}) {
  const {manager} = useAppContext('audio');
  const [caoimheReady, showCaoimhe] = useState(false);
  const [titleReady, showTitle] = useState(false);
  const t = useAppContext('language').trans('startup');

  useEffect(() => {
    const introduce = () => {
      showCaoimhe(true);
      manager.play('help', t('introduce daoimhe'), () => showTitle(true));
    };
    manager.play('help', t('should introduce'), introduce);
  }, []);


  const gotoHome = () => {
    manager.pause();
    history.push('/home')
  };

  const keyMapConfig = {
    ENTER: ()=> {
      if (titleReady) {
        history.push('/home')
      }
    }
  };

  useKeyMaps({
    keyboard: keyMapConfig,
    remote: keyMapConfig
  });

  return (
    <div className="Start">
      <Matrix speed={40}/>
      <div className={"background " + (caoimheReady ? 'active' : '')}></div>
      {titleReady && <Title blink={true} onClick={gotoHome} fadeIn={true} title="start"/>}
    </div>
  )
}

