import React, {useEffect, useState} from "react";
import {useAppContext} from 'hooks/useAppState';
import useLocalStorage from 'hooks/useLocalStorage';
import Title from 'components/Title';
import useUserInteracted from 'hooks/useUserInteracted';
import Matrix from 'components/Matrix';
import './Start.scss';

export default function Start({history}) {
  const userInteracted = useUserInteracted();
  const [titleReady, showTitle] = useState(false);
  const [token, setToken] = useLocalStorage('token', null);
  const {setStarted} = useAppContext('app');
  const path = token === null ? '/login' : '/home';

  useEffect(() => {
    if (!userInteracted) {
      return;
    }
    setStarted(true);
    history.push(path);
  }, [userInteracted, history]);

  const onMatrixReady = () => {
    showTitle(true);
  };

  return (
    <div className="Start">
      <Matrix speed={40} onReady={onMatrixReady}/>
      {titleReady && <Title blink={true} fadeIn={true} title="start"/>}
    </div>
  )
}

