import React, {useEffect} from "react";
import {fromEvent} from 'rxjs';
import {map, filter} from 'rxjs/operators';
import Keys from 'util/Keys';

export default function useKeyMaps(config, trigger = true) {
  useEffect(() => {
    let up;
    const down = fromEvent(document, 'keydown').subscribe((e) => {
      e.preventDefault();
    });
    const keyMap = e => {
      e.preventDefault();
      const name = Keys.getName(e.keyCode);
      const c = config[process.env.REACT_APP_KEY_TYPE];
      return typeof c[name] === 'function' ? c[name] : null
    };
    const keyFilter = fn => fn !== null;
    const onKey = fn => fn();
    up = fromEvent(document, 'keyup')
      .pipe(map(keyMap))
      .pipe(filter(keyFilter))
      .subscribe(onKey);
    return () => {
      up && up.unsubscribe();
      down && down.unsubscribe();
    }
  });
};